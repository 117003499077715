.page-team-info{
    padding: 15px 0 40px;

    li{
        line-height: 1;
        color: #444444;

        a{
            font-size: 15px;
        }
    }
    .page-team-name{
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
    }
    .page-team-mail{
        margin-top: 3px;
        color:#54879e;
        text-decoration: underline;
    }
}

.our-team-separator{
    border: none;
    height: 3px;
    background: color('section-secondary', '', '', $colors-section);
    color: color('section-secondary', '', '', $colors-section);
    width: 100px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 60px;
    float: left;
}

@media (max-width: 767px) {


    .page-team-info {
        text-align: center;
    }

    .page-team-text{
        text-align: center;
        width: auto;
    }





}